import styled from 'styled-components'
import { space } from 'styled-system'
import { COLOR_CONSTANTS } from 'theme'

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ bgColor }) => bgColor};
  ${space};
`

Divider.defaultProps = {
  bgColor: COLOR_CONSTANTS.SOLITUDE,
}

export default Divider
