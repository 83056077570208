import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space, COLOR_CONSTANTS, colors, radius } from 'theme'
import { parseTextFromServer, getImageByFeaturedMedia } from 'helpers'
import { Text } from 'components/Typography'
import { Flex } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || '350px'};
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
  margin: 0 ${space.s} ${space.l};
  border-radius: ${radius.m};
  box-shadow: 0px 0px 6px rgb(45 71 120 / 12%);
`

const StyledBodyWrapper = styled(Flex)`
  border-bottom-left-radius: ${radius.m};
  border-bottom-right-radius: ${radius.m};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const LINK_TEXTS = ['READ THE ARTICLE', 'READ THE ARTICLE', 'READ THE ARTICLE']

const BlogItemShort = ({ featured_media, title, slug, maxWidth, index }) => {
  const { featuredImageProps, WrapperComp } = getImageByFeaturedMedia(featured_media)
  const StyledImg = styled(WrapperComp)`
    object-fit: contain;
    max-height: 230px;
    min-height: 150px;
    max-width: 350px;
    & > picture > img {
      object-fit: contain !important;
    }
    border-radius: ${radius.m};
  `

  return (
    <StyledLink href={`/insights/${slug}/`} maxWidth={maxWidth}>
      <StyledImg {...featuredImageProps} />
      <StyledBodyWrapper p="l" flexDirection="column" bg={colors.white}>
        <Flex width="100%" height="114px">
          <Text textAlign="left" fontSize="l" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
            {parseTextFromServer(title)}
          </Text>
        </Flex>
        <StyledLinkText as="a" href={`/insights/${slug}/`} textAlign="left">
          <Text fontSize="l" textAlign="left" color="primary" as="span">
            {LINK_TEXTS[index]}
          </Text>
          <LocalImage mt="-2px" src={imageDoubleArrowRightBlue} />
        </StyledLinkText>
      </StyledBodyWrapper>
    </StyledLink>
  )
}

BlogItemShort.propTypes = {
  featured_media: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  slug: PropTypes.string,
  maxWidth: PropTypes.string,
  index: PropTypes.number.isRequired,
}

export default BlogItemShort
