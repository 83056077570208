/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
import ReactDOMServer from 'react-dom/server'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-s-alert'
import ReCAPTCHA from 'react-google-recaptcha'
import App from 'App'
import { colors, fontSizes, lineHeights, space, radius, fontWeights, COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH, AUTHORS, ERROR_MESSAGE } from 'consts'
import { toHumanReadable } from 'utils/date'
import { countArticleReadingTime, stripHtml, pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import NewsletterSubScription from 'components/NewsletterSubScription'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'
import Input from 'components/Input'
import Link from 'components/Link'
import ButtonWithLoading from 'components/ButtonWithLoading'
import Divider from 'components/Divider'
import arrowRight from 'static/images/arrow_right_dark.svg'
import checkboxEmpty from 'static/images/checkbox-empty.svg'
import checkboxChecked from 'static/images/checkbox-checked.svg'
import logo from 'static/logo/logo.png'
import facebookIcon from 'static/svg/facebook_icon.svg'
import linkedinIcon from 'static/svg/linkedin_icon.svg'
import twitterIcon from 'static/svg/twitter_icon.svg'
import imageDoubleArrowRightWhite from 'static/images/home/double_arrow_right_white.svg'
import logoBig from '../static/svg/logo_big.svg'

import CategoryItem from './components/CategoryItem'
// TODO: inspect why wy use .css file
import '../table.css'

import BlogItemShort from '../routes/Home/components/BlogItemShort'
import SocialMediasSection from '../routes/Home/components/SocialMediasSection'

const StyledArticleHeader = styled(Flex)`
  border-bottom: 1px solid ${COLOR_CONSTANTS.GLACIER_PEARL};
`

const HeroImg = styled.img`
  max-width: 730px;
  width: 100%;
  height: auto;
  object-fit: contain;
  pointer-events: none;
  margin-top: ${space.m};
  border-radius: ${radius.l};
`

const ArticleBody = styled.div`
  max-width: 730px;
  margin: 0 auto;
  color: #231f20;
`

const StyledAuthorImage = styled(LocalImage)`
  width: 48px;
  height: 48px;
  border-radius: ${radius.pill};
`

const StyledAuthorImageBio = styled(LocalImage)`
  width: 72px;
  height: 72px;
  border-radius: ${radius.pill};
`

const StyledTextWrapper = styled.div`
  & > section {
    color: #231f20;
    figure {
      margin: ${space.m} 0;
      padding: ${space.s} 0;
      text-align: center;
      border-radius: ${radius.l};
    }
    img {
      max-width: 100%;
      height: auto;
      border-radius: ${radius.l};
    }
    font-size: ${fontSizes.m};
    line-height: ${lineHeights.xxxl};
    a {
      color: ${colors.primary};
      font-weight: ${fontWeights.normal};
    }
    h2 {
      padding: 0 0 ${space.s} 0;
      color: ${COLOR_CONSTANTS.DENIM};
      font-weight: 600;
      line-height: 40px !important;
    }
    h3 {
      margin-top: ${space.l};
      margin-bottom: ${space.m};
      font-weight: 600;
      padding: ${space.m} 0 ${space.s} 0;
    }
    & > :first-child {
      margin-top: 0;
    }
  }
`

const StyledNav = styled.nav`
  display: ${(props) => (props.expand ? 'flex' : 'none')};
  flex-direction: column;
`

const StyledNavTitle = styled(Text)`
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const StyledArrowRight = styled(LocalImage)`
  transition: all 0.3s ease;
  transform: ${(props) => (props.expand ? 'rotate(-90deg)' : 'rotate(90deg)')};
`

const StyledLink = styled(Text)`
  color: ${(props) => props.color};
  text-decoration: ${(props) => (props.color === COLOR_CONSTANTS.COBALT ? 'underline' : 'none')};
  position: relative;
  width: fit-content;
  transition: all 0.2s ease;

  &:hover {
    color: ${COLOR_CONSTANTS.COBALT};
    opacity: 0.7;
  }

  &:last-child {
    margin-bottom: ${space.l} !important;
    padding-bottom: 0 !important;
  }
`

const ColumnWrapper = styled(Flex)`
  position: sticky;
  left: 0;
  top: calc(70px + 4vh);
  z-index: 1;
`

const StyledSocialMedialRefs = styled(Flex)`
  padding-top: ${space.l};
  border-top: 1px solid ${COLOR_CONSTANTS.GLACIER_PEARL};
  flex-direction: column;
`

const StyledSocialImageWrapper = styled(Flex)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: ${radius.circle};
  background-color: ${COLOR_CONSTANTS.SALT};
  align-items: center;
  justify-content: center;
  transition: filter 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  :not(:hover) {
    filter: saturate(0%) brightness(100%) contrast(100%);
  }
`

const StyledLatestWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: linear-gradient(180deg, #0250c9 0%, ${colors.primary} 100%);
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledIntroductionLink = styled(Link)`
  text-decoration: none;
  font-size: ${fontSizes.m};
  padding-left: ${space.xm};
  font-weight: ${fontWeights.normal};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    left: 3px;
    top: calc(50% - 2px);
    background-color: ${COLOR_CONSTANTS.COBALT};
    border-radius: ${radius.circle};
  }
`

const BannerWrapper = styled(Flex)`
  width: 100%;
  background: linear-gradient(180deg, #0250c9 0%, ${COLOR_CONSTANTS.BABY_BLUE_EYES} 100%);
  border-radius: ${radius.xxxl};
  position: sticky;
  right: 0;
  top: calc(70px + 4vh);
  z-index: 1;
`

const StyledInput = styled(Input)`
  border: 1px solid #b6cef0;
`

const StyledGetStartedButton = styled(ButtonWithLoading)`
  background: ${COLOR_CONSTANTS.WHITE};
  box-shadow: 0px 18px 24px -8px rgba(216, 233, 255, 0.32);
  border-radius: ${radius.pill};
  width: 218px;
  height: 100%;
`

const StyledInputLabel = styled(Text)`
  cursor: pointer;
`

const StyledCheckBoxLabel = styled(StyledInputLabel)`
  display: flex;
  align-items: flex-start;
  gap: ${space.xs};
`

const StyledCheckBoxIcon = styled(LocalImage)`
  width: 18px;
  height: 18px;
  object-fit: contain;
`

const StyledCheckbox = styled.input`
  min-width: 18px;
  height: 18px;
  margin-right: ${space.xs};
  cursor: pointer;
`

const StyledThanksBlock = styled(Flex)`
  width: 218px;
  border-radius: ${radius.xxxl};
  background-color: ${COLOR_CONSTANTS.SALT};
  border: ${COLOR_CONSTANTS.SALT};
`

const generateID = () => {
  const currentDate = new Date().getTime()
  const random1 = Math.floor(Math.random() * 1000)
  const random2 = Math.floor(Math.random() * 1000)
  const random3 = Math.floor(Math.random() * 1000)
  const id = `${currentDate}${random1}${random2}${random3}`
  return id
}

const { EMAIL, CHECK } = { EMAIL: 'email', CHECK: 'check' }

const EmailValidationSchema = Yup.object().shape({
  [EMAIL]: Yup.string()
    .email('Please use a valid email address')
    .required('Please use a valid email address'),
  [CHECK]: Yup.boolean()
    .oneOf([true], 'Please accept terms')
    .required('Please accept terms'),
})

const SOCIALS = [
  { target: 'share-vistasocial', icon: logo, link: 'https://vistasocial.com/calendar?share=', label: 'Vista Social' },
  {
    target: 'share-linkedin',
    icon: linkedinIcon,
    link: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    label: 'LinkedIn',
  },
  {
    target: 'share-twitter',
    icon: twitterIcon,
    link: 'https://x.com/share?url=',
    label: 'X (Twitter)',
  },
  {
    target: 'pop',
    icon: facebookIcon,
    link: 'https://www.facebook.com/share.php?u=',
    label: 'Facebook',
  },
]

const tocRegex = /Table\s*of\s*Contents/i

const join_template = `
  <div style="display: flex; align-items: center; justify-content: center; width: 100%; border-top: 1px solid ${COLOR_CONSTANTS.COSMIC_ENERGY};border-bottom: 1px solid ${COLOR_CONSTANTS.COSMIC_ENERGY}; margin: ${space.xl} 0;">
    <div style="display: flex; flex-direction: column; align-items: center; margin: ${space.l} auto; padding: ${space.m} 0;">
        <div style="display: flex;  align-items: center; justify-content: center; margin-bottom: ${space.m};">
            <span style="font-size: ${fontSizes.s}; font-weight: ${fontWeights.bold}; color: ${colors.secondaryText}; text-transform: uppercase;">
                Brought to you by
            </span>
            <img src="${logoBig}" alt="Try Vista Social for Free" style="max-width: 180px; margin-left: ${space.m}"/>
        </div>
        <p style="font-size: ${fontSizes.xxl}; color: ${COLOR_CONSTANTS.DENIM}; font-weight: ${fontWeights.bold}; margin: 0;">Try Vista Social for Free</p>
        <p style="font-size: ${fontSizes.m}; color: ${colors.secondaryText}; text-align: center;">A social media management platform that actually helps you grow with easy-to-use content planning, scheduling, engagement and analytics tools.</p>
        <a href="/pricing" class="join-template_button">Get Started Now</a>           
    </div>
</div>
`

const PostTemplate = ({
  data: {
    wordpressPost: post,
    allWordpressPost: { edges: allPostsRelated },
  },
  pageContext: { featuredImageUrl, content, imageMapping },
  location: { pathname, href: postLink },
}) => {
  const {
    title,
    // content,
    excerpt,
    modified,
    date,
    author: {
      name: authorName = 'Vista Social',
      description: authorDescription,
      avatar_urls: { wordpress_96 } = {},
    } = {},
    categories = [],
    yoast_meta: { yoast_wpseo_title: seoTitle, yoast_wpseo_metadesc: seoDescription } = {},
  } = post

  const NewsletterType = 'insights'

  const recaptchaRef = useRef()

  const [content_splitted, setContent_splitted] = useState(content.split('\n'))
  const [isTableOfContentsAlreadyHere, setIsTableOfContentsAlreadyHere] = useState(false)
  const [anchorNavList, setAnchorNavList] = useState([])
  const [expand, setExpand] = useState(true)
  const [activeAnchor, setActiveAnchor] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const readTime = countArticleReadingTime({ text: stripHtml(content) })

  const content_splitted_length = content_splitted.length
  const indexOfFirstH2 = content_splitted.findIndex((element) => {
    return element.includes('<h2') && !element.includes('<h2>Table of Contents</h2>')
  })
  const indexOfFirstP = content_splitted.findIndex((element) => element.includes('<p'))
  const insert_after = Math.round(content_splitted_length / 2)

  const onRecaptchaExpired = () => {
    recaptchaRef.current.execute()
  }

  const handleSubmitForm = async (values) => {
    try {
      const { email } = values

      let recaptcha

      if (recaptchaRef && recaptchaRef.current) {
        recaptcha = recaptchaRef.current.getValue()

        if (!recaptcha) {
          await recaptchaRef.current.executeAsync()

          recaptcha = recaptchaRef.current.getValue()

          recaptchaRef.current.reset()
        }
      } else {
        recaptcha = 'disabled'
      }

      if (!recaptcha || recaptcha === 'disabled') {
        Alert.error(`Error verifying reCAPTCHA, please try again or contact support.`, { timeout: 5000 })
      } else {
        setIsSubmitting(true)
        const response = await fetch(`${process.env.GATSBY_PUBLIC_GATSBY_API_URL}/subscribe`, {
          body: JSON.stringify({ email, recaptcha }),
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        })

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setIsSubmitted(true)
        }
      }
    } catch (error) {
      navigate('/500')
    } finally {
      setIsSubmitting(false)
    }
  }

  const scrollToAnchor = (anchor) => {
    const element = document.getElementById(anchor)
    if (element) {
      const offset = element.offsetTop
      window.scrollTo({ top: offset, behavior: 'smooth' })
    }
  }

  const renderSocialMediaRefs = () => {
    return (
      <Flex flexWrap="wrap" justifyContent={{ mobile: 'center', tablet: 'space-between' }}>
        {SOCIALS.map(({ icon, link, target, label }) => {
          let updatedLink = postLink
          if (target === 'share-vistasocial') {
            updatedLink = JSON.stringify({ link_url: postLink })
          } else if (target === 'share-twitter') {
            updatedLink = `${postLink}&text=${title}`
          }
          return (
            <StyledSocialImageWrapper
              key={link}
              mx={{ mobile: 'l', tablet: 0 }}
              onClick={() => {
                if (target === 'share-vistasocial') {
                  window.open(`${link}${updatedLink}`)
                } else {
                  window.open(`${link}${updatedLink}`, target, 'width=760,height=570')
                }
              }}
            >
              <LocalImage
                source={icon}
                width={target === 'share-vistasocial' ? '28px' : '32px'}
                height="32px"
                alt={`Share on ${label}`}
              />
            </StyledSocialImageWrapper>
          )
        })}
      </Flex>
    )
  }

  const renderTableOfContents = () => {
    const filteredAnchorNavList = anchorNavList.filter((el) => el.id !== 'introduction')
    return (
      <div
        style={{
          backgroundColor: COLOR_CONSTANTS.SALT,
          flexDirection: 'column',
          borderRadius: radius.xl,
          padding: space.m,
          marginTop: space.m,
          marginBottom: space.m,
        }}
      >
        <h2
          style={{ marginTop: 0, color: COLOR_CONSTANTS.SALUTE, fontSize: fontSizes.l, fontWeight: fontWeights.medium }}
        >
          Table of contents
        </h2>
        <nav style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: space.s }}>
          {filteredAnchorNavList.map((el) => (
            <StyledIntroductionLink
              key={el.id}
              color={COLOR_CONSTANTS.COBALT}
              href={`#${el.id}`}
              dangerouslySetInnerHTML={{ __html: el.html }}
            />
          ))}
        </nav>
      </div>
    )
  }

  const table_of_contents = renderTableOfContents()
  const table_of_contents_string = ReactDOMServer.renderToString(table_of_contents)

  const handleExpand = () => {
    setExpand((prev) => !prev)
  }

  const addIDToFirstP = () => {
    if (indexOfFirstP > -1) {
      const element = content_splitted[indexOfFirstP]
      const pContent = element.replace(/<\/?p>/g, '')
      content_splitted[indexOfFirstP] = `<section id="introduction"><p>${pContent}</p>`
      setAnchorNavList((prev) => [{ id: 'introduction', html: `<span>Introduction</span>` }, ...prev])
    }
  }

  const closingSection = () => {
    const lastChild = content_splitted[content_splitted.length - 1]
    content_splitted[content_splitted.length - 1] = `${lastChild}</section>`
  }

  // additions ID to H2 tags and filling anchorNavList and checking if table of contents is already here.
  const uniqueIDTableOfContent = () => {
    try {
      const updatedContent = content_splitted.map((element) => {
        const match = /<h2 id="(.*?)">(.*?)<\/h2>/i.exec(element)
        const h2Content = element.replace(/<\/?h2>/g, '').replace(/\//g, '')

        if (match) {
          const [, anchorId, text] = match
          const formattedItem = {
            id: anchorId,
            html: `<span>${text}</span>`,
          }
          setAnchorNavList((prev) => [...prev, formattedItem])
          return `</section><section id="${anchorId}"><h2>${text}</h2>`
        }

        if (element.includes('<h2>')) {
          const id = generateID()
          if (tocRegex.test(h2Content)) {
            return `<h2>${h2Content}</h2>`
          } else {
            setAnchorNavList((prev) => [...prev, { id, html: `<span>${h2Content}</span>` }])
            return `</section><section id="${id}"><h2>${h2Content}</h2>`
          }
        }
        return element
      })

      setContent_splitted(updatedContent)
    } catch (e) {
      console.error(e)
    }
  }

  let content_updated = ''

  if (!isTableOfContentsAlreadyHere) {
    content_updated = `${content_splitted
      .slice(0, indexOfFirstH2 - 1)
      .join('\n')}\n${table_of_contents_string}\n${content_splitted
      .slice(indexOfFirstH2 - 1, insert_after)
      .join('\n')}\n${join_template}\n${content_splitted.slice(insert_after).join('\n')}`
  } else {
    content_updated = `${content_splitted
      .slice(0, insert_after)
      .join('\n')}\n${join_template}\n${content_splitted.slice(insert_after).join('\n')}`
  }

  const related_posts = allPostsRelated.map(({ node }) => {
    const { featured_media } = node
    if (featured_media && featured_media.source_url) {
      if (imageMapping[featured_media.source_url]) {
        featured_media.source_url = imageMapping[featured_media.source_url]
      }
    }

    return node
  })

  while (content_updated.includes('<iframe')) {
    content_updated = content_updated.replace(
      '<iframe',
      `<div class='instagram-embed-container' style='min-height: auto;'><div><div><xiframe loading="lazy"`
    )
  }

  while (content_updated.includes('<xiframe')) {
    content_updated = content_updated.replace('xiframe', `iframe`)
  }

  while (content_updated.includes('iframe>')) {
    content_updated = content_updated.replace('iframe>', `ixframe></div></div></div>`)
  }

  while (content_updated.includes('ixframe')) {
    content_updated = content_updated.replace('ixframe', `iframe`)
  }

  while (content_updated.includes('captioned/')) {
    content_updated = content_updated.replace('captioned/', ``)
  }

  // const { source_url: featuredImageUrl } = post.featured_media || {}

  const checkContent = () =>
    content_splitted.map((element) => {
      if (element.includes('<h2>')) {
        const h2Content = element.replace(/<\/?h2>/g, '')
        if (tocRegex.test(h2Content)) {
          setIsTableOfContentsAlreadyHere(true)
        }
      }
    })

  useEffect(() => {
    // checking if Table of Content and Id "introduction" has already added
    checkContent()
    addIDToFirstP()
    uniqueIDTableOfContent()
    closingSection()
    // console.log('window.instgrm', window.instgrm)
    if (window.instgrm) {
      console.log('instagram embed is available')
      window.instgrm.Embeds.process()
    } else {
      console.log('instagram embed is not available')
    }
    // console.log('all loaded?')
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY
      const windowBottom = windowTop + window.innerHeight
      const newActiveAnchor = anchorNavList.find((anchor) => {
        const anchorElement = document.getElementById(anchor.id)
        if (anchorElement) {
          const rect = anchorElement.getBoundingClientRect()
          const elementTop = rect.top + windowTop
          const elementBottom = elementTop + rect.height
          return elementTop < windowBottom && elementBottom > windowTop + window.innerHeight * 0.2
        }
        return false
      })
      if (newActiveAnchor) {
        setActiveAnchor(newActiveAnchor)
      }
    }
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [anchorNavList, setActiveAnchor])

  const authorUrl = `/insights/author/${authorName.toLowerCase().replace(' ', '-')}`
  return (
    <App>
      <SEO
        title={seoTitle || title}
        description={seoDescription || excerpt}
        author={authorName}
        image={featuredImageUrl}
        type="article"
        path={pathname}
      />
      <Flex flexDirection="column" alignItems="center" position="relative">
        <Flex maxWidth={CONTAINER_MAX_WIDTH} width="100%" mx="auto" px={{ mobile: 0, tablet: 'l' }}>
          <StyledArticleHeader
            py={{ mobile: 'm', tablet: 'xl' }}
            alignItems="center"
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            width="100%"
          >
            <Flex flexDirection="column" mt="s" pl={{ mobile: 'm', tablet: 0 }} pr="m" order={{ mobile: 2, tablet: 1 }}>
              <H1
                fontSize="3xl"
                dangerouslySetInnerHTML={{ __html: title }}
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'left', tablet: 'left' }}
              />
              <Flex mb="xs" py="s" justifyContent="space-between" width="100%" alignItems="center" flexWrap="wrap">
                <Flex mt="s" alignItems="center">
                  <Text color="secondaryText" fontSize="s">
                    Updated on {toHumanReadable(modified)}
                  </Text>
                  <Box mx="s" borderRadius={radius.pill} width="6px" minWidth="6px" height="6px" bg="#B6CEF0" />
                  <Text color="secondaryText" fontSize="s">
                    {readTime} min to read
                  </Text>
                </Flex>
              </Flex>

              <Flex mb="m" py="s" justifyContent="space-between" width="100%" alignItems="center" flexWrap="wrap">
                <Flex mt="xs" alignItems="center">
                  <StyledAuthorImage src={wordpress_96} />
                  <Flex ml="s" flexDirection="column" justifyContent="center">
                    <Text fontSize="m" fontWeight="medium" color="primary" as="a" href={authorUrl} rel="author">
                      {authorName}
                    </Text>
                    <Text fontSize="s">{AUTHORS.getTitle(authorName)}</Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex>
                {categories.length > 0 && (
                  <Flex width="100%" justifyContent="flex-start" flexWrap="wrap">
                    {categories.map((category, index) => (
                      <CategoryItem category={category} key={index} />
                    ))}
                  </Flex>
                )}
              </Flex>

              <Flex>
                <Text color="secondaryText" fontSize="s" mt="m">
                  Published {toHumanReadable(date)}
                </Text>
              </Flex>
            </Flex>
            {featuredImageUrl && (
              <Flex
                alignItems="center"
                justifyContent="center"
                maxWidth="730px"
                width="100%"
                pr={{ mobile: 'm', tablet: 0 }}
                pl="m"
                order={{ mobile: 1, tablet: 2 }}
              >
                <HeroImg title={title} alt={title} src={featuredImageUrl} />
              </Flex>
            )}
          </StyledArticleHeader>
        </Flex>
        <Flex display={{ mobile: 'block', tablet: 'none' }} width="100%" m="l">
          {renderSocialMediaRefs()}
        </Flex>
        <Grid
          mt={{ mobile: 's', tablet: 'l' }}
          pt={{ mobile: 's', tablet: 'm' }}
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          gridTemplateColumns={{ mobile: '1fr', tablet: '250px 1fr', desktop: '250px 1fr 250px' }}
          gridGap="l"
          alignItems="start"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
        >
          <ColumnWrapper display={{ mobile: 'none', tablet: 'block' }}>
            <StyledNavTitle
              as="h5"
              mb="m"
              fontWeight="bold"
              fontSize="m"
              color={COLOR_CONSTANTS.DENIM}
              onClick={handleExpand}
            >
              <span>Content</span>
              <StyledArrowRight src={arrowRight} expand={expand} alt="arrow icon" />
            </StyledNavTitle>
            <StyledNav expand={expand}>
              {anchorNavList.map((el) => (
                <StyledLink
                  as="a"
                  key={el.id}
                  onClick={() => scrollToAnchor(el.id)}
                  dangerouslySetInnerHTML={{ __html: el.html }}
                  pb="s"
                  mb="xs"
                  fontSize="s"
                  color={activeAnchor?.id === el.id ? COLOR_CONSTANTS.COBALT : COLOR_CONSTANTS.SAPPHIRE}
                />
              ))}
            </StyledNav>
            <StyledSocialMedialRefs>
              <Text as="h5" mb={space.s} fontSize={fontSizes.m} fontWeight="bold" color={COLOR_CONSTANTS.SALUTE}>
                Share
              </Text>
              {renderSocialMediaRefs()}
            </StyledSocialMedialRefs>
          </ColumnWrapper>
          {/* TODO: inspect why wy use .css file */}
          <ArticleBody className="post">
            <StyledTextWrapper dangerouslySetInnerHTML={{ __html: content_updated }} />

            <Flex mt="xl" flexDirection="column">
              <Text fontSize="xxl" fontWeight="medium">
                About the Author
              </Text>
              <Divider my="m" bgColor={COLOR_CONSTANTS.COSMIC_ENERGY} />
              <Flex alignItems="center">
                <StyledAuthorImageBio src={wordpress_96} />
                <Flex ml="s" flexDirection="column" justifyContent="center">
                  <Text as="a" href={authorUrl} rel="author" fontSize="l">
                    {authorName}
                  </Text>
                  <Text>{AUTHORS.getTitle(authorName)}</Text>
                </Flex>
              </Flex>
              <Text mt="s" color="secondaryText">
                {authorDescription}
              </Text>
            </Flex>
          </ArticleBody>
          <BannerWrapper mt="l" px="m" py="xm" flexDirection="column" display={{ mobile: 'none', desktop: 'flex' }}>
            <Text mb="s" fontSize="l" color={COLOR_CONSTANTS.WHITE} fontWeight="bold" textAlign="left" as="h5">
              Never Miss a Trend
            </Text>
            <Text mb="m" fontSize="s" color={COLOR_CONSTANTS.WHITE} textAlign="left">
              Our newsletter is packed with the hottest posts and latest news in social media.
            </Text>
            {isSubmitted ? (
              <>
                <StyledThanksBlock px="l" py="xm" flexDirection="column">
                  <Text textAlign="center" mb="xs" as="h5" fontSize="l" fontWeight="bold">
                    Thanks for subscribing!
                  </Text>
                  <Text textAlign="center" fontSize="m">
                    We’ll begin sending you weekly insights soon.
                  </Text>
                </StyledThanksBlock>
              </>
            ) : (
              <Formik
                initialValues={{ [EMAIL]: '', [CHECK]: false }}
                validationSchema={EmailValidationSchema}
                onSubmit={handleSubmitForm}
              >
                {({ values, handleChange, handleSubmit, setFieldValue, touched, errors }) => {
                  const error =
                    (errors[EMAIL] && touched[EMAIL] && errors[EMAIL]) ||
                    (errors[CHECK] && touched[CHECK] && errors[CHECK])
                  return (
                    <Flex flexDirection="column" alignItems="center">
                      <Flex alignItems="center" flexWrap="wrap" maxWidth="218px">
                        <StyledInputLabel
                          as="label"
                          color={COLOR_CONSTANTS.WHITE}
                          mb="m"
                          mx="auto"
                          fontSize="m"
                          fontWeight="bold"
                          maxWidth="218px"
                          width="218px"
                        >
                          Email Address
                          <StyledInput
                            mt="s"
                            placeholder="Enter your email"
                            value={values[EMAIL]}
                            onChange={handleChange(EMAIL)}
                            borderRadius="pill"
                            maxWidth="218px"
                            width="218px"
                          />
                        </StyledInputLabel>
                        <Flex alignItems="flex-start" justifyContent="space-between" maxWidth="218px" mx="auto">
                          <StyledCheckbox
                            type="checkbox"
                            id="check-box-email"
                            name="check-box-email"
                            checked={values[CHECK]}
                            style={{ display: 'none' }}
                            onChange={() => {
                              setFieldValue(CHECK, !values[CHECK])
                            }}
                          />
                          <StyledCheckBoxLabel
                            as="label"
                            for="check-box-email"
                            color={COLOR_CONSTANTS.WHITE}
                            ml="auto"
                            fontSize="xs"
                            width="180px"
                          >
                            {values[CHECK] ? (
                              <StyledCheckBoxIcon src={checkboxChecked} alt="Vista Social checkbox-checked" />
                            ) : (
                              <StyledCheckBoxIcon src={checkboxEmpty} alt="Vista Social checkbox-unchecked" />
                            )}
                            <Text as="span">
                              Yes, I would like to receive email marketing communication from Vista Social. I understand
                              that I can unsubscribe at any time.
                            </Text>
                          </StyledCheckBoxLabel>
                        </Flex>
                        {error && (
                          <Flex mt="s">
                            <Text color="error" maxWidth="218px" mx="auto" fontWeight="bold" textAlign="center">
                              {error}
                            </Text>
                          </Flex>
                        )}
                        <Box mt="m" maxWidth="218px" height={pxToRem(40)}>
                          <StyledGetStartedButton
                            type="submit"
                            isLoading={isSubmitting}
                            loaderColor={COLOR_CONSTANTS.COBALT}
                            onClick={() => {
                              if (!isSubmitting) {
                                handleSubmit()
                              }
                            }}
                          >
                            <Text fontSize="m" color="primary" fontWeight="bold">
                              Subscribe
                            </Text>
                          </StyledGetStartedButton>
                        </Box>
                      </Flex>
                    </Flex>
                  )
                }}
              </Formik>
            )}
          </BannerWrapper>
        </Grid>

        {related_posts.length > 0 && (
          <StyledLatestWrapper mt={{ mobile: 'l', tablet: 'xl' }}>
            <Container
              my={{ mobile: 'l', tablet: 'xl' }}
              pl={{ mobile: 'l', tablet: 0 }}
              pr={{ mobile: 'l', tablet: 0 }}
              position="relative"
              maxWidth={CONTAINER_MAX_WIDTH}
              width="100%"
              height="100%"
            >
              <Flex
                mt="l"
                justifyContent="space-between"
                width="100%"
                flexDirection={{ mobile: 'column', tablet: 'row' }}
                px="l"
                alignItems="center"
              >
                <Flex flexDirection="column">
                  <Text fontSize="xl" color="primaryText" textAlign="left" />
                </Flex>
                <StyledLinkText as="a" href="/insights" textAlign="left">
                  <Text fontSize="l" fontWeight="medium" textAlign="left" color="white" as="span">
                    READ MORE ARTICLES
                  </Text>
                  <LocalImage src={imageDoubleArrowRightWhite} />
                </StyledLinkText>
              </Flex>
              <Flex mt="l" flexWrap="wrap" justifyContent="center" px={{ mobile: 'm', tablet: 0 }}>
                {related_posts.map((article, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <BlogItemShort key={index} {...article} index={index} />
                ))}
              </Flex>
            </Container>
          </StyledLatestWrapper>
        )}

        <SocialMediasSection />

        <NewsletterSubScription type={NewsletterType} />

        <ReCAPTCHA
          sitekey="6Ld6gM0gAAAAAPwkW5CO806wVq_C1BanZ12XeZyI"
          size="invisible"
          ref={recaptchaRef}
          onExpired={onRecaptchaExpired}
        />
      </Flex>
      <Footer />
    </App>
  )
}

PostTemplate.defaultProps = {}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PostTemplate

export const query = graphql`
  query($id: String!, $related_posts_ids: [String]) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date
      modified
      author {
        name
        avatar_urls {
          wordpress_96
        }
        description
      }
      categories {
        slug
        name
      }
      featured_media {
        title
        alt_text
        caption
        source_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allWordpressPost(filter: { id: { in: $related_posts_ids } }) {
      edges {
        node {
          id
          path
          title
          excerpt
          date
          modified
          slug
          status
          template
          format
          slug
          sticky
          content
          categories {
            slug
            name
            description
          }
          author {
            name
            avatar_urls {
              wordpress_96
            }
            description
          }
          featured_media {
            title
            alt_text
            caption
            source_url
          }
        }
      }
    }
  }
`
