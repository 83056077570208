import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import { Flex } from 'components/Layout'
import Container from 'components/Container'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import LocalImage from 'components/LocalImage'

import bgImageSocialMedias from 'static/images/home/bg_social_medias.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const BgImgBackground = styled(LocalImage)`
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || 0};
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
`

const StyledGetStartedButtonWrapper = styled(Flex)`
  box-shadow: 0px 18px 24px -8px rgb(36 130 253 / 32%);
`

const StyledButtonGradient = styled(Button.Gradient)`
  height: 100%;
`

const SocialMediasSection = ({ background }) => {
  return (
    <Wrapper bg={background}>
      <Container
        my={{ mobile: 'l', tablet: 'xl' }}
        pb={{ mobile: 0, tablet: 'l' }}
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <BgImgBackground
          src={bgImageSocialMedias}
          width="auto"
          objectFit={{ mobile: 'contain', tablet: 'cover' }}
          height="100%"
          display={{ mobile: 'none', tablet: 'block' }}
        />
        <Flex pt={{ mobile: '0', tablet: 'xxl' }} alignItems="center" flexDirection="column" position="relative">
          <Text fontWeight="bold" fontSize="3xl" color={COLOR_CONSTANTS.SALUTE}>
            You have many things to do.
          </Text>
          <Text fontWeight="bold" fontSize="3xl" color={COLOR_CONSTANTS.SALUTE}>
            Let us help you with social media.
          </Text>
        </Flex>
        <Flex mt="m" pt="s" alignItems="center" flexDirection="column">
          <Text color="secondaryText" fontSize="l">
            Use our free plan to build momentum for your social media presence.
          </Text>
          <Text color="secondaryText" fontSize="l">
            Or skip ahead and try our paid plan to scale your social media efforts.
          </Text>
        </Flex>
        <Flex mt="xl" alignItems="center" flexDirection="column">
          <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
            <StyledButtonGradient as="a" href="/pricing">
              <Text p="m" fontSize="s">
                Get started now
              </Text>
            </StyledButtonGradient>
          </StyledGetStartedButtonWrapper>
          <Text color="secondaryText" mt="m" pt="s" pb="m">
            P.S. It will be a piece of cake 🍰 with Vista Social
          </Text>
        </Flex>
      </Container>
    </Wrapper>
  )
}

SocialMediasSection.defaultProps = {
  background: COLOR_CONSTANTS.WHITE,
}

SocialMediasSection.propTypes = {
  background: PropTypes.string,
}

export default SocialMediasSection
